.list {
  border-right: solid 1px var(--mantine-color-dark-8);
}

.card {
  transition: 350ms ease-in-out;
  cursor: pointer;
  &:hover {
    scale: 1.01;
  }
}

body,
html {
  overflow: hidden;
}
