.input {
  border: solid 1px var(--mantine-color-gray-3);
}

.lightButton {
  background: #dee2e6;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.25) inset;
  font-weight: 500;
  font-size: 16px;
  height: fit-content;
  padding: 12px 20px;
  height: fit-content;
  color: #42474d;
  transition: 100ms;
  &:hover {
    background: #ced4da;
    color: #42474d;
    scale: 0.995;
  }
}

.darkButton {
  background: #2e2e2e;
  font-weight: 500;
  font-size: 16px;
  height: fit-content;
  padding: 12px 20px;
  box-shadow: 0px -1px 0px 0px #000 inset,
    0px 1px 0px 0px rgba(255, 255, 255, 0.1) inset;
  transition: 100ms;
  &:hover {
    background: #202021;
    color: #ffffff;
    scale: 0.995;
  }
}
